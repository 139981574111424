/* Calendar container */
.calendar-controls {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .calendar-controls select {
    margin-right: 10px;
    padding: 5px;
    font-size: 16px;
  }
  
  .calendar table {
    width: 100%;
    border-collapse: collapse;
    height: 90vh;
  }


  .dateevents{
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
  }
  .calunder{
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
  }

  .calunder1{
   justify-self:baseline ;
  }

  .event{
    background-color: #007bff;
    cursor: pointer;
    color: #fff;
    margin-bottom: 2px;
  }
  
  .calendar th, .calendar td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    /* background-color: #007bff; */
  }
  
  .calendar th {
    background-color: #0583EE; /* Light grey background for header */
  }
  
  .calendar td {
    background-color: #fff;
    width: 14%;
    height: 18vh; /* White background for dates */
  }
  
  /* Highlight event date */
  .calendar .event-date {
    background-color: #007bff; /* Blue background for event date */
    color: #fff;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .calendar-controls {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .calendar-controls select {
      margin: 5px 0;
    }
  }
  