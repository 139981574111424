.mainindexpages {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.loginbox {
    width: 300px;
    height: fit-content;
    background: rgb(5, 131, 238);
    background: linear-gradient(0deg, rgba(5, 131, 238, 1) 1%, rgba(19, 211, 250, 1) 100%);
    padding: 20px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 10px 5px;

}

.loginform {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
}

.loginform input{
    width: 100%;
    height: 15px;
    border-radius: 10px;
    border: none;
    padding: 10px;
    outline-offset: none
}

.loginform input:focus{
   background-color: rgba(198, 216, 231, 0.89);
}

.loginform button{
    width: 106%;
    height: 40px;
    border-radius: 10px;
    border: none;
    color: rgb(5, 131, 238);
    
  
}

.loginform button:hover{
    cursor: pointer;
    border: 1px solid rgb(5, 131, 238);
    color: black;
    box-shadow: 0 0 10px 1px rgb(5, 131, 238);
  
}

h1{
    margin: 0;
}
h2{
    margin: 0;
}

h3{
    margin: 0;
}

h4{
    margin: 0;
}

h5{
    margin: 0;
}


