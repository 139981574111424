.pendingtasksdiv {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    margin-top: 30px;
    /* background-color: aqua; */
    width: 100%;
    height: fit-content;
    gap: 10px;
    overflow-y: auto;
    max-height: 40vh;
}

.newtaskcontant {
    display: flex;
    padding: 10px 10px;
    /* background-color: yellow; */
    /* width: 100%; */
    border: 2px dashed #0583EE;
    border-radius: 10px;
    min-height: 100px;
    height: fit-content;
    flex-direction: column;
 

}

.taskingdoneandassign{
    /* background-color: aqua; */
    display: flex;
    gap: 20px;
}