.customerassignbox{
    /* background-color: yellow; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.customerassigncard{
    /* background-color: violet; */
    width: 500px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px;
}

.customerassigncard:hover{
    box-shadow: 0 0 10px 1px #0583EE;
    cursor: pointer;
    scale: 1.01;
}




.data-plans-table {
    border-collapse: collapse;
    width: 100%;
    color: rgb(100, 100, 100);
}

.data-plans-table th,
.data-plans-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
}

.data-plans-table td:hover {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
    cursor: pointer;
}


.selected-cell {
    background-color: #0587EF;
    color: white;
}


.det{
    /* background-color: yellow; */
    padding: 30px;
    border: 1px dashed #0583EE;
}