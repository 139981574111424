.invoicebox{
    /* background-color: aqua; */
    border: 5px dashed #0583EE;
padding: 20px;
/* display: flex;
justify-content: space-between; */
margin-top: 50px;
}

.invoiceinside{
    display: flex;
    justify-content: space-between;
}