.jobsdiv {
    /* background-color: aqua; */
    padding: 20px;
    overflow-y: auto;
    max-height: 500px;
    margin-top: 20px;
    border: 1px dotted #0583EE;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.joblist {
    border-bottom: 1px solid gray;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 0;
}

.joblist p {
    margin: 0;

}