.sidemanu {
    background-color: #0583EE;
    box-shadow: 5px 0px 1px 0.1px;
    width: 50px;
    /* width: 400px; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 20px;
    padding: 5vh 0px;
    padding-left: 10px;
    /* height: 90vh; */
    overflow: hidden;
    /* position: sticky;
    top: 0; */

}

.manu {
    width: fit-content;
    /* background-color: aqua; */
}

.iconnav {
    /* background-color: violet; */

    width: 300px;
    height: fit-content;
}

.iconnav2 {
    /* background-color: violet; */

    width: 300px;
    height: fit-content;
}

.iconnav2:hover {
    cursor: pointer;
    text-decoration: underline;
}

.iconnav h3 {
    color: white;
    cursor: context-menu;
}

.iconnav2 h3 {
    color: white;
}

.navmaunu {
    display: none;
    /* display: block; */
    transform: 2000ms ease;
}

.sidemanu:hover {

    width: fit-content;
    position: absolute;
    padding-right: 20px;

}

.iconnav:hover .navmaunu {
    display: block;


}

.navmaunu ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.navigater {
    color: white;

}

.navigater:hover {
    cursor: pointer;
    text-decoration: underline white;

    font-weight: bold;
}