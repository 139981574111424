
.emaillist{
    /* background-color: aqua; */
    margin-top: 50px;


}

.emailbox{
    width: 96%;
    padding: 2%;
    border: 1px dotted #0583EE;
    margin-bottom: 20px;
}