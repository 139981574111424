.navbar{
    background-color: #0583EE;
    width: 94%;
    height: 70px;
    box-shadow: 0 1px 5px 1px;
    padding: 10px 3%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navlogo{
    width: 150px;
    height: 100%;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.logoutandsearch input{

    width: 300px;
    height: 30px;
    border: none;
    box-shadow: 0 0 5px 0.5px;
    border-radius: 10px;
    margin-right: 10px;
}
.logoutandsearch{

    display: flex;
}

.logoutandsearch button:hover{

    cursor: pointer;
    
}