.blogList-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  padding: 30px;
}




@media (max-width: 768px) {
  .blogList-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .blogList-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}



.chip {
  font-size: 0.7rem;
  background: linear-gradient(to right, #0583EE, #469ae4);
  color: #fff;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  width: fit-content;
  text-transform: capitalize;
}



.blogItem-wrap {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.blogItem-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 0.5rem;
}

.blogItem-wrap h3 {
  margin: 0.5rem 0 1rem 0;
  flex: 1;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
}

.blogItem-desc {
  position: relative;
  max-height: 50px;
  overflow: hidden;
  padding-right: 0.6rem;
  font-size: 0.8rem;
  color: #a9a9a9;
}

.blogItem-desc::before {
  position: absolute;
  content: '...';
  bottom: 0;
  right: 0;
}

.blogItem-wrap footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
}

.blogItem-link {
  text-decoration: none;
  color: inherit;
}

.blogItem-author {
  display: flex;
  align-items: center;
}

.blogItem-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.blogItem-author p {
  font-size: 0.6rem;
  color: #0583EE;
  font-weight: 600;
  margin: 0;
}


@media screen and (max-width: 768px) {

  .blogList-wrap {
    /* display: grid; */
    /* grid-template-columns: repeat(3, 1fr); */
    grid-gap: 7rem;
    /* padding: 30px; */
  }
}