.mainbody {
    display: flex;
}

.dashbordbody {

    /* background-color: aqua; */
    width: 96%;
    height: fit-content;
    padding: 2%;
}


.dashbordboxcantainer {
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* padding: 1%; */
    gap: 20px;
    margin-bottom: 50px;
}

.dashboardbox {
    background-color: yellow;
    width: 22%;
    height: 120px;
    display: flex;
    align-items: flex-end;
    padding: 1%;
    color: white;
    cursor: pointer;
}

.dashboardbox:hover{
    box-shadow: 0px 10px 10px 1px black;
    scale: 1.05;
   

}