.header {
    display: flex;
    /* background-color: aqua; */
}

.tablep {

    /* background-color: yellow; */
    width: 100%;
    text-align: left;
    margin-top: 10px;
    border: 1px solid #4180b7;
}

.tablephead {
    background-color: #0583EE;
    color: white;
}

.Tableheader {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.formalbtn {
    background-color: #0583EE;
    color: white;
    padding: 5px 5%;
    border: 1px solid #053157;

}

.formalbtn:hover {
    border: 1px solid #053157;
    background-color: white;
    color: #0583EE;
    cursor: pointer;
    box-shadow: 0px 0px 3px 1px;

}

.formalbtn:active {
    scale: 1.009;

}

.infodivvv {
    display: flex;
    align-items: center;
}

.editinput {
    padding: 5px 10px;
}

.socailmedialinks {
    display: flex;
    /* background-color: #0583EE; */
    width: 100%;
    height: 100px;
    justify-content: space-around;
    align-items: center;
    /* margin-top: 50px; */
}

.socailmedialinks a {
   cursor: pointer;
}

.popup {
    width: 100%;
    height: 100vh;
    /* background-color: rgb(231, 0, 0); */
    position: fixed;
    top: 0;
    z-index: 2;
}

.popupbg {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background-color: rgba(37, 37, 37, 0.822);

}

.popupcontant {
    width: 60%;
    height: fit-content;
    max-height: 70vh;
    overflow-y: auto;
    background-color: white;
    position: fixed;
    left: 20%;
    top: 50%;
    transform: translateY(-50%);
    padding: 20px;

}

.newenteryform {
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.newenteryform div {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    /* background-color: yellow; */

}



.newenteryform label {
    width: fit-content;


}

.newenteryform input {
    width: 80%;
    height: 30px;
    padding: 0 10px;
    background-color: #cbdcec;
    border-radius: 5px;
    border: 1px solid #053157;

}

.newenteryform textarea {
    width: 80%;
    height: 100px;
    padding: 0 10px;
    background-color: #cbdcec;
    border-radius: 5px;
    border: 1px solid #053157;

}

.newenteryform select {
    width: 80%;
    height: 30px;
    padding: 0 10px;
    background-color: #cbdcec;
    border-radius: 5px;
    border: 1px solid #053157;

}










/* sdafc */
.newenteryform2 {
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.newenteryform2div {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;

}



.newenteryform2 label {
    width: fit-content;


}

.newenteryform2 input {
    width: 80%;
    height: 30px;
    padding: 0 10px;
    background-color: #cbdcec;
    border-radius: 5px;
    border: 1px solid #053157;

}

.newenteryform2 textarea {
    width: 80%;
    height: 100px;
    padding: 0 10px;
    background-color: #cbdcec;
    border-radius: 5px;
    border: 1px solid #053157;

}

.newenteryform2 select {
    width: 80%;
    height: 30px;
    padding: 0 10px;
    background-color: #cbdcec;
    border-radius: 5px;
    border: 1px solid #053157;

}




















.textarea{
    padding: 10px 10px;
    background-color: #cbdcec;
    border-radius: 5px;
    border: 1px solid #053157;
}
.hovar:hover{
cursor: pointer;
}
.formalbtncncl {
    background-color: #fbfdff;
    color: rgb(252, 1, 1);
    padding: 5px 5%;
    border: 1px solid #f10901;

}

.formalbtncncl:hover {
    border: 1px solid #053157;
    background-color: white;
    color: #0583EE;
    cursor: pointer;
    box-shadow: 0px 0px 3px 1px;

}

.formalbtncncl:active {
    scale: 1.009;

}

.profilepix{
    width: 40%;
    height: 100px;

}

.profilepix img{
    /* width: 100%; */
    height: 200px;
    
}

